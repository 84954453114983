import React, { ChangeEvent, useRef, useState, useMemo, useEffect } from 'react'
import * as yup from 'yup'
import {
   isEmptyObject,
   renderDateOrNothing,
} from '../../../../common/utils/functions'
import { TableWrapper } from '../../../common/table/TableWrapper'
import {
   PageHeader,
   LoadingOverlay,
   Search,
   Button,
   Modal,
   CsvDownloadForm,
   Filters,
   SlidePanel,
   Accordion,
   Tag,
   Svg,
   Drawer,
   ItemReorder,
   TrueOrFalse,
} from '../../../common'
import { Select } from '../../../common/form/fields'
import FilterButton from '../../../common/filters/filterButton/filterButton'
import {
   convertConnectionsToOptions,
   convertCategoriesToOptions,
   convertLocationToOptions,
   convertItemTypesToOptions,
} from '../../../common/filters/helpers'
import {
   DateRangeFilter,
   DropdownFilter,
   ToggleFilter,
} from '../../../common/filters/filterTypes'
import { COSTING_TABLE } from './constants'
import {
   useHistory,
   useFilters,
   useConnections,
   useActiveLocations,
   useCategories,
   useSettings,
   useItemTypes,
   useTableOptions,
} from '../../../../hooks'
import { Option } from '../../../common/form/fields/dropdown/dropdown.type'
import FilterTags from '../../../common/filters/filterTags/filterTags'
import {
   Filters as FiltersObject,
   FiltersForAPI,
} from '../../../../hooks/useFilters/useFilters.type'
import { format, subDays } from 'date-fns'
import { FORMATS } from '../../../../constants/datetime'
import { flattenFilterObj } from '../../../../hooks/useFilters/useFilters.helpers'
import * as styles from './costingHistory.module.scss'
import ColumnsButton from '../../../common/filters/columnsButton/columnsButton'
import { BiColumns } from 'react-icons/bi'
import { PAGINATION } from '../../../../constants/tables'
import {
   convertSettingsToCustomAccessors,
   generateColumnItemOptions,
   renderOrderedTableColumns,
} from './helpers'
import { SearchObject } from '../../../../types'
import { Timeout } from 'react-number-format/types/types'
import { formatPrice } from '../../../../constants/global'
import * as tableStyles from '../../../common/table/table.module.scss'

const {
   extraClassName,
   placeholder,
   tableTitle,
   downloadCsvText,
   basicPath,
   key,
   modalTitle,
   primaryActionText,
   secondaryActionText,
   exportFileName,
   initialPayload,
   downloadRequest,
   defaultColumnOrder,
} = COSTING_TABLE

const schema = yup.object().shape({
   startDate: yup.date().max(new Date(), 'Start Date cannot be in the future'),
   endDate: yup.date().when(['startDate'], (startDate: Date) => {
      if (startDate) {
         return yup
            .date()
            .min(startDate, 'End Date cannot be earlier than the Start Date')
            .max(new Date(), 'End Date cannot be in the future')
      }
   }),
   type: yup.string().required('Type filter is required'),
})

export function formatFiltersForAPI(filters: FiltersObject): FiltersForAPI {
   function formatDate(date: Date) {
      return format(date, FORMATS.filterDateAPI)
   }

   const startDateFilter = {
      ...(filters?.startDate?.value instanceof Date && {
         startDate: formatDate(filters?.startDate?.value),
      }),
   }
   const endDateFilter = {
      ...(filters?.endDate?.value instanceof Date && {
         endDate: formatDate(filters?.endDate?.value),
      }),
   }

   return {
      ...flattenFilterObj(filters),
      ...startDateFilter,
      ...endDateFilter,
   }
}

const defaultTypeValue = { label: '', value: 'connection' }

const defaultStartDate = { label: '', value: subDays(new Date(), 1) }

const defaultEndDate = { label: '', value: new Date() }

const columnHeaders = [
   {
      Header: 'User',
      accessor: 'BorrowingUserDisplayName',
      Cell: (cell: {
         row: { original: { BorrowingUserDisplayName: string } }
      }) => (
         <div className={tableStyles.tableColumnCellWrapper}>
            {cell.row.original.BorrowingUserDisplayName}
         </div>
      ),
      width: 150,
      minWidth: 20,
   },
   {
      Header: 'Location',
      accessor: 'ToolLoanLocation',
      Cell: (cell: { row: { original: { ToolLoanLocation: string } } }) => (
         <div className={tableStyles.tableColumnCellWrapper}>
            {cell.row.original.ToolLoanLocation}
         </div>
      ),
      width: 150,
      minWidth: 20,
   },
   {
      Header: 'Item Name',
      accessor: 'Title',
      Cell: (cell: { row: { original: { Title: string } } }) => (
         <div className={tableStyles.tableColumnCellWrapper}>
            {cell.row.original.Title}
         </div>
      ),
      width: 150,
      minWidth: 20,
   },
   {
      Header: 'Type',
      accessor: 'ToolTypeDesc',
      align: 'center',
      width: 150,
      minWidth: 20,
   },
   {
      Header: 'Qty',
      accessor: 'Quantity',
      align: 'center',
      width: 75,
      minWidth: 20,
   },
   {
      Header: 'Item Detail',
      accessor: 'Description',
      Cell: (cell: { row: { original: { Description: string } } }) => (
         <div className={tableStyles.tableColumnCellWrapper}>
            {cell.row.original.Description}
         </div>
      ),
      width: 150,
      minWidth: 20,
   },
   {
      Header: 'Manufacturer',
      accessor: 'ManufacturerName',
      align: 'center',
      width: 150,
      minWidth: 20,
   },
   {
      Header: 'Model No.',
      accessor: 'ModelNumber',
      align: 'center',
      width: 120,
      minWidth: 20,
   },
   {
      Header: 'Serial No.',
      accessor: 'SerialNumber',
      align: 'center',
      width: 120,
      minWidth: 20,
   },
   {
      Header: 'Barcode',
      accessor: 'ItemBarcode',
      align: 'center',
      width: 120,
      minWidth: 20,
   },
   {
      Header: 'Category',
      accessor: 'CategoryName',
      align: 'center',
      width: 120,
      minWidth: 20,
   },
   {
      Header: 'Purchase Date',
      accessor: 'PurchaseDate',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Purchase Price',
      accessor: 'PurchasePrice',
      align: 'center',
      width: 120,
      minWidth: 20,
   },
   {
      Header: 'Warranty Date',
      accessor: 'WarrantyDate',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Rental Tool',
      accessor: 'IsRentalTool',
      align: 'center',
      width: 130,
      minWidth: 20,
      Cell: (row: { original: { IsRentalTool: boolean } }) => (
         <TrueOrFalse status={row.original?.IsRentalTool} />
      ),
   },
   {
      Header: 'Rental Start Date',
      accessor: 'RentalDate',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Rental End Date',
      accessor: 'RentalReturnDate',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Rate-Day',
      accessor: 'CustomRentalRate1',
      align: 'center',
      width: 110,
      minWidth: 20,
      Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate1),
   },
   {
      Header: 'Rate-Week',
      accessor: 'CustomRentalRate2',
      align: 'center',
      width: 110,
      minWidth: 20,
      Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate2),
   },
   {
      Header: 'Monthly Rate',
      accessor: 'CustomRentalRate3',
      align: 'center',
      width: 110,
      minWidth: 20,
      Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate3),
   },
   {
      Header: 'Hourly Rate',
      accessor: 'CustomRentalRate4',
      align: 'center',
      width: 110,
      minWidth: 20,
      Cell: ({ row }) => formatPrice(row?.original?.CustomRentalRate4),
   },
   {
      Header: 'Custom Date 1 Value',
      accessor: 'CustomDate1Value',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Custom Date 2 Value',
      accessor: 'CustomDate2Value',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Custom Text 1 Value',
      accessor: 'CustomText1Value',
      width: 150,
      minWidth: 20,
   },
   {
      Header: 'Custom Text 2 Value',
      accessor: 'CustomText2Value',
      width: 150,
      minWidth: 20,
   },
   {
      Header: 'Custom URL 1',
      accessor: 'CustomURL1Value',
      Cell: (cell: { row: { original: { CustomURL1Value: string } } }) => (
         <div className={tableStyles.tableColumnCellWrapper}>
            <a href={cell.row.original.CustomURL1Value} target={'_blank'}>
               {cell.row.original.CustomURL1Value}
            </a>
         </div>
      ),
   },
   {
      Header: 'Custom URL 2',
      accessor: 'CustomURL2Value',
      Cell: (cell: { row: { original: { CustomURL2Value: string } } }) => (
         <div className={tableStyles.tableColumnCellWrapper}>
            <a href={cell.row.original.CustomURL2Value} target={'_blank'}>
               {cell.row.original.CustomURL2Value}
            </a>
         </div>
      ),
   },
   {
      Header: 'Website URL',
      accessor: 'WebsiteUrl',
      Cell: (cell: { row: { original: { WebsiteUrl: string } } }) => (
         <div className={tableStyles.tableColumnCellWrapper}>
            <a href={cell.row.original.WebsiteUrl} target={'_blank'}>
               {cell.row.original.WebsiteUrl}
            </a>
         </div>
      ),
      width: 130,
      minWidth: 20,
   },
   {
      Header: 'Owners Manual URL',
      accessor: 'AttachmentUrl',
      Cell: (cell: { row: { original: { AttachmentUrl: string } } }) => (
         <div className={tableStyles.tableColumnCellWrapper}>
            <a href={cell.row.original.AttachmentUrl} target={'_blank'}>
               {cell.row.original.AttachmentUrl}
            </a>
         </div>
      ),
      width: 130,
      minWidth: 20,
   },
   {
      Header: 'Return Warehouse',
      accessor: 'DefaultLocation',
      Cell: (cell: { row: { original: { DefaultLocation: string } } }) => (
         <div className={tableStyles.tableColumnCellWrapper}>
            {cell.row.original.DefaultLocation}
         </div>
      ),
      width: 200,
      minWidth: 20,
   },
   {
      Header: 'Loan Date',
      accessor: 'DateAccepted',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Due Back Date',
      accessor: 'PlannedLoanReturnDisplay',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Return Date',
      accessor: 'ReturnDate',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Report Start Date',
      accessor: 'ReportStartDate',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Report End Date',
      accessor: 'ReportEndDate',
      align: 'center',
      width: 120,
      minWidth: 20,
      Cell: renderDateOrNothing,
   },
   {
      Header: 'Duration Day(s)',
      accessor: 'DurationDays',
      align: 'center',
      width: 110,
      minWidth: 20,
   },
   {
      Header: 'Duration Hour(s)',
      accessor: 'DurationTimeHrs',
      align: 'center',
      width: 110,
      minWidth: 20,
   },
   {
      Header: 'Total Duration Day(s)',
      accessor: 'LoanDuration',
      align: 'center',
      width: 110,
      minWidth: 20,
   },
]

export const CostingHistory = () => {
   const { query, skip, take } = PAGINATION
   const { settings, isQuerySuccess: isSettingsSuccess } = useSettings()

   const customAccessorsHeaderList = useMemo(() => {
      return isSettingsSuccess ? convertSettingsToCustomAccessors(settings) : []
   }, [isSettingsSuccess])

   const {
      data: itemData,
      list,
      setPath,
      setPageOptions,
      totalItems,
      isLoading,
      isRefetching,
      // @ts-ignore
   } = useHistory({
      skip,
      take,
      query,
      searchForAuditItem: '',
      sentStartDate: undefined,
      sentEndDate: undefined,
      selectedConditions: [],
      selectedConfrmationType: [],
      selectedItemConfirmation: 0,
      offset: 0,
      selectedConnections: [],
      selectedLocations: [],
      selectedCategories: [],
      selectedStatus: [],
      selectedManufacturers: [],
   })

   const buttonRef = useRef<HTMLInputElement | null>(null)
   const [searchMode, setSearchMode] = useState<boolean>(false)
   const [pageSized, setPageSized] = useState<number>(PAGINATION.pageSized)
   const [isPageReset] = useState<boolean>(false)
   const [searchCriteria, setSearchCriteria] = useState<string>('')

   const {
      errors,
      modify,
      reset,
      remove: removeFilter,
      save,
      savedFilters,
      tmpFilters,
   } = useFilters(schema, {
      type: defaultTypeValue,
      startDate: defaultStartDate,
      endDate: defaultEndDate,
   })

   const { list: connections } = useConnections()
   const { list: categories } = useCategories()
   const { list: locations } = useActiveLocations()
   const { list: itemTypes } = useItemTypes()

   const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
   const [filterValues, setFilterValues] = useState({})
   const formRef = useRef<HTMLFormElement>()
   const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
   const [fullDownload] = useState(downloadRequest)
   const [_filteredDownload, setFilteredDownload] = useState(downloadRequest)
   const [isDownloading, setIsDownloading] = useState<boolean>(false)

   const applySavedFilters = (filters: FiltersObject) => {
      setFiltersOpen(false)
      handleGetItems(searchCriteria, 0, pageSized, filters)
      setFilterValues(formatFiltersForAPI(filters))
   }

   const handleGetItems = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: FiltersObject
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)

      setFilterValues(formattedFilters)

      const criteria = {
         offset: -1,
         skip: skip,
         take: take,
         query,
         ...formattedFilters,
      }
      list(criteria)
   }

   const handleClose = () => {
      setIsModalVisible(false)
      buttonRef.current.click()
   }

   const showDownloadModal = () => {
      setIsModalVisible(true)
   }

   const handleSelectChange = (event: ChangeEvent<HTMLFormElement>) => {
      const { name, value } = event.target

      modify(name, '', value)
   }

   const handleToggleChange = (name: string, label: string, value: boolean) => {
      modify(name, label, value)
   }

   const handleDropdownChange = (
      name: string,
      label: string,
      options: Option[]
   ) => {
      modify(
         name,
         label,
         options?.map((option) => ({
            label: option.label,
            value: option.value,
         }))
      )
   }

   const handleRemoveTag = (name: string, id: string) => {
      const newFilters = removeFilter(name, id)
      applySavedFilters(newFilters)
      setFilterValues(formatFiltersForAPI(newFilters))
   }

   const handleFilterSave = async () => {
      const isSaved = await save()

      if (isSaved) {
         applySavedFilters(tmpFilters)
      }
   }

   const handleResetFilters = () => {
      formRef.current.reset()
      reset()
      applySavedFilters({})
   }

   const connectionsOptions = convertConnectionsToOptions(connections)
   const categoryOptions = convertCategoriesToOptions(categories)
   const locationOptions = convertLocationToOptions(locations)
   const itemTypeOptions = convertItemTypesToOptions(itemTypes)

   useEffect(() => {
      setPath(basicPath, key)
      setPageOptions(initialPayload)
   }, [])

   useEffect(() => {
      setFilteredDownload({
         type: 0,
         startDate: '1 jan 2021',
         endDate: '5 jan 2022',
         selectedConnections: [],
         selectedLocations: [],
         selectedCategories: [],
         onlyToolsMarkedAsRental: false,
         offset: null,
         skip: 0,
         take: 10,
         query: searchCriteria,
         ...savedFilters,
      })
   }, [searchCriteria])

   // COLUMNS FUNCTIONALITY
   const [tmpColumnList, setTmpColumnList] = useState<string[]>([])
   const [columnSettingsOpen, setColumnSettingsOpen] = useState<boolean>(false)

   const {
      isLoading: tableColumnOptionsLoading,
      tableColumnOptions: itemTableOptions,
      update: saveTableColumnOptions,
   } = useTableOptions('costingHistoryTable')

   const columns = useMemo(() => {
      if (isSettingsSuccess && !tableColumnOptionsLoading) {
         const enabledCustomAccessorsHeaderList =
            customAccessorsHeaderList.filter((accessor) => accessor.isEnabled)
         // create list of disabled accessors
         const disabledCustomAccessorsNameList = customAccessorsHeaderList
            .filter((accessor) => !accessor.isEnabled)
            .reduce((arr, obj) => [...arr, obj.accessor], [])
         // change custom header to match settings
         enabledCustomAccessorsHeaderList.map((customAccessor) => {
            const customMatch = columnHeaders.find(
               (header) => header.accessor === customAccessor.accessor
            )
            customMatch.Header = customAccessor.label
         })
         // disable any custom headers not enabled from settings
         const enabledHeaders = columnHeaders.filter(
            (header) =>
               !disabledCustomAccessorsNameList.includes(header.accessor)
         )
         const columnList = itemTableOptions?.length
            ? itemTableOptions
            : defaultColumnOrder
         return renderOrderedTableColumns(enabledHeaders, columnList)
      }
   }, [
      columnHeaders,
      tableColumnOptionsLoading,
      isSettingsSuccess,
      savedFilters,
      tmpFilters,
      itemTableOptions,
      settings,
   ])

   const defaultColumnItems = useMemo(() => {
      return generateColumnItemOptions(defaultColumnOrder, columns)
   }, [settings])

   const currentColumnItems = useMemo(() => {
      return generateColumnItemOptions(
         itemTableOptions ? itemTableOptions : defaultColumnOrder,
         columns
      )
   }, [settings])

   const handleReorderColumns = (newColumns) => setTmpColumnList(newColumns)

   const handleResetColumns = () => {
      saveTableColumnOptions([])
      setColumnSettingsOpen(false)
   }

   const handleSaveColumnReorder = () => {
      setColumnSettingsOpen(false)
      saveTableColumnOptions(tmpColumnList)
   }

   let [throttleTimer] = useState<number | Timeout>(5000)
   const [searchObj, setSearchObj] = useState<SearchObject>({})

   const handleSearch = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)
      setFilterValues({ ...formattedFilters, query })

      const criteria = { offset: -1, query, skip, take, ...formattedFilters }
      setSearchObj(criteria)
      list(criteria)
   }

   const onKeyUp = () => {
      clearTimeout(throttleTimer)
      throttleTimer = setTimeout(throttleCompleted, 1000)
   }

   const onKeyDown = () => {
      clearTimeout(throttleTimer)
   }

   const throttleCompleted = () => {
      const { query, skip } = searchObj
      handleGetItems(query, skip, pageSized, savedFilters)
   }

   const loading = useMemo(
      () => isLoading || isRefetching || tableColumnOptionsLoading,
      [isLoading, isRefetching, tableColumnOptionsLoading]
   )

   return (
      <div>
         {loading && <LoadingOverlay />}
         <PageHeader title={tableTitle}>
            <Button
               onClick={showDownloadModal}
               preserveText
               variant="plain"
               minWidth="0"
            >
               <Svg id="download" width={8} height={8} /> {downloadCsvText}
            </Button>
         </PageHeader>
         <div className={styles.searchWrapper}>
            <div className={styles.searchWrapperSearch}>
               <Search
                  handleQuery={handleSearch}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  pageSized={pageSized}
                  placeHolder={placeholder}
                  setSearchCriteria={setSearchCriteria}
                  onKeyUp={onKeyUp}
                  onKeyDown={onKeyDown}
               />
            </div>
            <div className={styles.searchWrapperActionButtons}>
               <ColumnsButton
                  isActive={itemTableOptions?.length > 0}
                  onClick={() => setColumnSettingsOpen(!columnSettingsOpen)}
               />
               <FilterButton
                  isActive={!isEmptyObject(savedFilters)}
                  onClick={() => setFiltersOpen(!filtersOpen)}
               />
            </div>
         </div>

         <FilterTags filters={savedFilters} onClick={handleRemoveTag} />
         {!Object.entries(savedFilters).length && (
            <Tag label="Yesterday to Today shown" />
         )}
         {!isLoading && !tableColumnOptionsLoading && columns?.length > 0 && (
            <TableWrapper
               isLoading={isLoading || tableColumnOptionsLoading}
               data={itemData}
               columns={columns}
               totalCount={totalItems}
               getItems={handleGetItems}
               takeItems={PAGINATION.take}
               skipItems={PAGINATION.skip}
               setSearchMode={setSearchMode}
               searchMode={searchMode}
               isPageReset={isPageReset}
               setPageSized={setPageSized}
               extraClassName={extraClassName}
               searchCriteria={searchCriteria}
            />
         )}

         {/* Column reorder panel */}
         <SlidePanel
            isOpen={columnSettingsOpen}
            onClose={() => setColumnSettingsOpen(false)}
         >
            <Drawer
               heading="Table Columns"
               icon={
                  <BiColumns
                     style={{
                        marginRight: '0.5rem',
                        marginTop: '-0.5rem',
                     }}
                  />
               }
               onReset={handleResetColumns}
               onSave={handleSaveColumnReorder}
            >
               <p>
                  Add or remove columns. To change the column order, drag and
                  drop a field.
               </p>
               {!loading && settings && (
                  <ItemReorder
                     defaultItems={defaultColumnItems}
                     items={
                        itemTableOptions?.length
                           ? currentColumnItems
                           : defaultColumnItems
                     }
                     onChange={handleReorderColumns}
                     permissions={{
                        AssignedToKitName: 'KitEnabled',
                        Quantity: 'QuantityEnabled',
                        StatusQuantity: 'QuantityEnabled',
                        CustomRentalRate1: 'CustomRentalRate1Enabled',
                        CustomRentalRate2: 'CustomRentalRate2Enabled',
                        CustomRentalRate3: 'CustomRentalRate3Enabled',
                        CustomRentalRate4: 'CustomRentalRate4Enabled',
                        CustomText1Value: 'CustomText1Enabled',
                        CustomText2Value: 'CustomText2Enabled',
                        CustomDate1Value: 'CustomDate1Enabled',
                        CustomDate2Value: 'CustomDate2Enabled',
                        CustomURL1Value: 'CustomURL1Enabled',
                        CustomURL2Value: 'CustomURL2Enabled',
                        DefaultLocation: 'EnableLocationTracking',
                        CurrentLocation: 'EnableLocationTracking',
                        LastAuditDate: 'AuditEnabled',
                        LastAuditConditionDesc: 'AuditEnabled',
                     }}
                  />
               )}
            </Drawer>
         </SlidePanel>

         <SlidePanel isOpen={filtersOpen} onClose={() => setFiltersOpen(false)}>
            <Filters onReset={handleResetFilters} onSave={handleFilterSave}>
               <form ref={formRef}>
                  {!!settings?.EnableLocationTracking && (
                     <Accordion isOpen title="Type">
                        <Select
                           error={errors?.type}
                           options={[
                              { value: 'Connection', key: 'connection' },
                              { value: 'Location', key: 'location' },
                           ]}
                           id="type"
                           onChange={handleSelectChange}
                           value={tmpFilters?.type?.value as string}
                           isControlled
                           hideDefaultOption
                           noMargin
                        />
                     </Accordion>
                  )}

                  {(tmpFilters?.type?.value === 'connection' ||
                     !settings?.EnableLocationTracking) && (
                     <DropdownFilter
                        defaultValue={
                           tmpFilters?.selectedConnections?.value as Option[]
                        }
                        isMulti
                        id="selectedConnections"
                        onChange={handleDropdownChange}
                        options={connectionsOptions}
                        title="Connection"
                     />
                  )}
                  {!!settings?.EnableLocationTracking &&
                     tmpFilters?.type?.value === 'location' && (
                        <DropdownFilter
                           defaultValue={
                              tmpFilters?.selectedLocations?.value as Option[]
                           }
                           isMulti
                           id="selectedLocations"
                           onChange={handleDropdownChange}
                           options={locationOptions}
                           title="Location"
                        />
                     )}

                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedCategories?.value as Option[]
                     }
                     isMulti
                     id="selectedCategories"
                     onChange={handleDropdownChange}
                     options={categoryOptions}
                     title="Category"
                  />

                  <DropdownFilter
                     defaultValue={
                        tmpFilters?.selectedItemTypes?.value as Option[]
                     }
                     isMulti
                     id="selectedItemTypes"
                     onChange={handleDropdownChange}
                     options={itemTypeOptions}
                     title="Item Type"
                  />

                  <ToggleFilter
                     defaultValue={tmpFilters?.onlyToolsMarkedAsRental?.value}
                     id="onlyToolsMarkedAsRental"
                     onChange={handleToggleChange}
                     title="Only tools marked as rental"
                  />

                  <DateRangeFilter
                     dateProps={{
                        from: {
                           defaultValue: tmpFilters?.startDate?.value as Date,
                           error: errors?.startDate,
                           id: 'startDate',
                           placeholder: 'Start date',
                        },
                        to: {
                           defaultValue: tmpFilters?.endDate?.value as Date,
                           error: errors?.endDate,
                           id: 'endDate',
                           placeholder: 'End date',
                        },
                     }}
                     id="startDate"
                     onChange={modify}
                     title="Date range"
                  />
               </form>
            </Filters>
         </SlidePanel>
         <Modal
            isModalVisible={isModalVisible}
            closeModal={handleClose}
            title={modalTitle}
         >
            <CsvDownloadForm
               primaryActionText={primaryActionText}
               secondaryActionText={secondaryActionText}
               criteria={''}
               data={itemData}
               fileName={exportFileName}
               closeModal={handleClose}
               path={basicPath.DOWNLOAD}
               query={searchCriteria}
               fullDownload={fullDownload}
               filters={filterValues}
               setIsDownloading={setIsDownloading}
               isDownloading={isDownloading}
               buttonRef={buttonRef}
            />
         </Modal>
      </div>
   )
}
