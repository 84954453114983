type ReorderItem = {
   value: string
   label: string
}

const renderOrderedTableColumns = (
   columns: any[],
   columnAccessors: string[]
) => {
   return columnAccessors.reduce((columnsToRender, accessor: string) => {
      const column = columns?.find((col) => col?.accessor === accessor)

      return !!column ? [...columnsToRender, column] : columnsToRender
   }, [])
}

const generateColumnItemOptions = (
   colList: string[],
   columns: any[]
): ReorderItem[] => {
   return colList
      .map((accessor) => {
         const tableCol = columns?.find((col) => col?.accessor === accessor)
         if (tableCol) {
            return {
               value: tableCol?.accessor,
               label: tableCol?.headerTitle ?? tableCol?.Header,
            }
         }
      })
      .filter((col) => col !== undefined)
}

const convertSettingsToCustomAccessors = (settings) => [
   {
      accessor: 'CustomRentalRate1',
      label: settings?.CustomRentalRate1Label,
      isEnabled: settings?.CustomRentalRate1Enabled,
   },
   {
      accessor: 'CustomRentalRate2',
      label: settings?.CustomRentalRate2Label,
      isEnabled: settings?.CustomRentalRate2Enabled,
   },
   {
      accessor: 'CustomRentalRate3',
      label: settings?.CustomRentalRate3Label,
      isEnabled: settings?.CustomRentalRate3Enabled,
   },
   {
      accessor: 'CustomRentalRate4',
      label: settings?.CustomRentalRate4Label,
      isEnabled: settings?.CustomRentalRate4Enabled,
   },
   {
      accessor: 'CustomDate1Value',
      label: settings?.CustomDate1Label,
      isEnabled: settings?.CustomDate1Enabled,
   },
   {
      accessor: 'CustomDate2Value',
      label: settings?.CustomDate2Label,
      isEnabled: settings?.CustomDate2Enabled,
   },
   {
      accessor: 'CustomText1Value',
      label: settings?.CustomText1Label,
      isEnabled: settings?.CustomText1Enabled,
   },
   {
      accessor: 'CustomText2Value',
      label: settings?.CustomText2Label,
      isEnabled: settings?.CustomText2Enabled,
   },
   {
      accessor: 'CustomURL1Value',
      label: settings?.CustomURL1Label,
      isEnabled: settings?.CustomURL1Enabled,
   },
   {
      accessor: 'CustomURL2Value',
      label: settings?.CustomURL2Label,
      isEnabled: settings?.CustomURL2Enabled,
   },
]

export {
   generateColumnItemOptions,
   renderOrderedTableColumns,
   convertSettingsToCustomAccessors,
}
